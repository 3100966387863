<template>
  <div class="member-elem p-4 border-bottom">
    <div class="row d-flex flex-row justify-content-center align-items-center">
      <div class="col-12 col-sm-12 col-md-5 col-lg-4">
        <img :src="member.img" class="w-100 member-profile-pic rounded-circle" :alt="member.name"/>
      </div>
      <div class="col-12 col-sm-12 col-md-7 col-lg-8">
        <h1 class="member-name mt-3 mt-sm-3 mt-md-1 mt-lg-3 mb-1 mb-sm-1 mb-md-2 mb-lg-3 pt-3 pb-1 pb-sm-1 pb-md-2 pb-lg-3">
          {{ member.name }}
        </h1>
        <p v-for="text in member.texts" :key="text.id">{{ text.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team",
  props: {
    member: Object,
  },
};
</script>

<style scoped>
@import "../css/Team.scss";
</style>
