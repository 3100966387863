<template>
  <div class="col-12 px-0 pb-5">
    <div id="teams" class="w-100 teams pageTitle py-4">
      <parallax>
        <img src="/assets/img/krave-studios-team-header.jpg" alt="" />
      </parallax>
      <h1 class="pageTitle">Teams</h1>
    </div>
    <div class="teams-container container py-4">
      <team v-for="member of teamMembers" :key="member.id" :member="member"></team>
    </div>
  </div>
</template>

<script>
import Parallax from "vue-parallaxy";
import Team from "@/components/Team";
export default {
  name: "Teams",
  components: {
    Parallax,
    Team,
  },
  data: () => {
    return {
      slide: 0,
    };
  },
  computed: {
    teamMembers() { return this.$store.getters.teamMembers },
    footer() { return this.$store.state.footer }
  },
  mounted() {
    this.$store.commit("SET_FOOTER", true);
    this.$store.dispatch('getTeamMembers');
  }
};
</script>

<style scoped>
#teams {
  width: 100vw;
  height: 45vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#teams h1.pageTitle {
  width: 100%;
  position: absolute;
  font-family: "Lato", Lato, "Ubuntu", sans-serif;
  font-size: 50px;
  font-weight: lighter;
  color: #fff;
  margin: auto;
  text-transform: uppercase;
  text-align: center;
}
div.member-elem:last-of-type {
  border-bottom: 0 !important;
}
</style>
